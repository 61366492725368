import { useEffect, useState } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import { parseHits } from '@nintendo-of-america/search';
import {
  useLocalizer,
  deserializeHash,
} from '@nintendo-of-america/react-hooks';
import * as cache from '@local/lib/helpers/sessionCache';

const appendHitsToCachedHits = (cachedHits, hits) => {
  const startPosition = hits?.[0]?.__position || 0;
  const start = (cachedHits || []).slice(0, startPosition);
  return [...start, ...hits];
};

const useCachedSearchHits = (options) => {
  const [cachedHits, setCachedHits] = useState([]);
  const { isSearching, cacheKey, cachePrefix, activeIndexName, rawSearchHits } =
    options;

  const router = useRouter();
  const localizer = useLocalizer();

  useEffect(() => {
    const hash = deserializeHash(router.asPath);
    // Excluding p (for page) because we want
    // all the results to generate one long list
    // eslint-disable-next-line no-unused-vars
    const { p, ...cacheSet } = hash;
    const cacheData = cache.get(cacheKey, {});

    const cacheSetKey = `${cachePrefix}_${JSON.stringify(cacheSet)}_${
      router.locale
    }`;

    const cachedHits = isSearching
      ? cacheData[cacheSetKey] || []
      : appendHitsToCachedHits(cacheData[cacheSetKey], rawSearchHits);

    cache.set(cacheKey, {
      [cacheSetKey]: cachedHits,
    });

    // Make sure items are in the correct order
    const sortedHits = Object.values(cachedHits).sort(
      (item1, item2) => item1.__position - item2.__position
    );

    const hits = parseHits(sortedHits, {
      locale: router.locale,
      localizer,
      indexName: activeIndexName,
    });
    setCachedHits(hits);
  }, [
    isSearching,
    cacheKey,
    cachePrefix,
    rawSearchHits,
    router.asPath,
    router.locale,
    activeIndexName,
    localizer,
  ]);

  return cachedHits;
};

export default useCachedSearchHits;

/**
 * Merges promos and products into one array, ensuring
 * that a promo appears after every n of products, where
 * n is determined by the passed in interval.
 * @param {Object[]} promos - An array of promos
 * @param {Object[]} products - An array of products
 * @param {Object} options - Object containing options to configure the merge logic
 * @param {Int} options.interval - The number of products to insert inbetween promos
 * @param {Boolean} options.isPromoInFirstPosition - Determines if the returned array should start with a promo or not.
 * @returns {Object[]} A merged array of promos and products.
 */
const mergePromosWithProducts = (promos, products, options) => {
  if (!promos?.length) return products || [];
  if (!products?.length) return promos || [];

  const { interval = 10, isPromoInFirstPosition } = options || {};
  let promoIndex = 0;

  return products.reduce((acc, product, index) => {
    if (index === 0 && isPromoInFirstPosition) {
      const promo = promos?.[promoIndex];
      if (promo) {
        promoIndex++;
        return [promo, product];
      } else {
        return [product];
      }
    } else if (index > 0 && index % interval === 0) {
      const promo = promos?.[promoIndex];
      if (promo) {
        promoIndex++;
        return [...acc, promo, product];
      } else {
        return [...acc, product];
      }
    }

    return [...acc, product];
  }, []);
};

export default mergePromosWithProducts;

import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AttributeFilterSet } from '@local/components';
import { combineRefinements } from '@local/lib/helpers';

const PLPAttributeFilterSet = (props) => {
  const { attribute, items, cmsItems, refine, cmsRefine, ...options } = props;

  const combinedItems = useMemo(() => {
    return combineRefinements(attribute, items, cmsItems);
  }, [attribute, items, cmsItems]);

  const combinedRefine = useCallback(
    ({ value, cmsValue }) => {
      if (value) {
        refine(value);
      }

      if (cmsValue) {
        cmsRefine(cmsValue);
      }
    },
    [refine, cmsRefine]
  );

  return (
    <AttributeFilterSet
      refine={combinedRefine}
      attribute={attribute}
      items={combinedItems}
      {...options}
    />
  );
};

const refinementItem = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string,
  count: PropTypes.number,
  isRefined: PropTypes.bool,
});

PLPAttributeFilterSet.defaultProps = {
  autoExpand: true,
  collapsible: true,
  startCollapsed: true,
  displayOptions: {},
  operator: 'and',
};

PLPAttributeFilterSet.propTypes = {
  /**
   * When autoExpand is set to true, if the AttributeFilterSet
   * has filters checked in on initialization, then the component
   * will automatically expand (ignoring startCollapsed).
   */
  autoExpand: PropTypes.bool,
  collapsible: PropTypes.bool,
  startCollapsed: PropTypes.bool,
  title: PropTypes.string,
  attribute: PropTypes.string,
  operator: PropTypes.oneOf(['and', 'or']),
  hidden: PropTypes.bool,
  limit: PropTypes.number,
  shouldCombineRefinements: PropTypes.bool,
  showMore: PropTypes.bool,
  showMoreLabel: PropTypes.string,
  showLessLabel: PropTypes.string,
  cmsRefine: PropTypes.func,
  refine: PropTypes.func,
  sort: PropTypes.func,
  suffix: PropTypes.string,
  cmsItems: PropTypes.arrayOf(refinementItem),
  items: PropTypes.arrayOf(refinementItem),
  analytics: PropTypes.shape({
    pageName: PropTypes.string,
  }),
};

export default PLPAttributeFilterSet;
